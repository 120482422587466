import React, { Component } from 'react';
import './style.css';

import $ from 'jquery';
export default class Footer extends Component {


componentDidMount()
{

   $('.scroll-up').click(function() {
        $("html, body").animate({
            scrollTop:0
        },1000);

});
}

render() {
 
    return (

<div>

  
<footer>

    <span>© 2020 Hashtag Systems Inc. All Rights Reserved.</span>

    <div className="scroll-up">
        <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.932818 5.08579C1.21015 5.35361 1.6498 5.35361 1.9271 5.08579L4.28511 2.72924L4.28511 11.3715C4.28511 11.7667 4.60551 12.0871 5.00075 12.0871C5.396 12.0871 5.7164 11.7667 5.7164 11.3715L5.7164 2.7292L8.07298 5.08579C8.35728 5.36036 8.81031 5.35247 9.08487 5.06822C9.35273 4.79088 9.35273 4.35123 9.08487 4.0739L5.50674 0.495765C5.2276 0.215957 4.77449 0.215369 4.49468 0.494464L4.49338 0.495765L0.915248 4.0739C0.64064 4.3582 0.648523 4.81122 0.932818 5.08579Z"/>
        </svg>
    </div>
</footer>

</div>

    )
  }
}
