import React, { Component } from 'react';
import { NavLink,Link } from "react-router-dom";
import './style.css';
import ReactImageAppear from 'react-image-appear';
import LazyLoad from 'react-lazyload';
import Header from '../../components/header/index.jsx';
import Footer from '../../components/footer/index.jsx';


export default class OnlymatdetailPage extends Component {


  render() {


    return (
   

<div className="">



<Header> </Header>

<section className="single_post_section">
    <div className="single_post_desc single-stories">
   
        <div className="single_post_title">
           <div> <div className="single_post_tag single_post_tag_color_orange">E-commerce</div>
             <p className="website-by">Designed By RESHMA RAJAN.</p></div>
            <h1>Only mat</h1>
            
        </div>

        {/*<div className="single_post_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
         Duis aute irure dolor in. quis nostrud exercitation ullamco laboris uis nostrud 
         exercitation</div>*/}
         <div>
             
             <div className="single_post_text">A Leading Distributor of Bsy Noni Black Hair Magic Products Which Includes Hair Dye, Hair Color And Shampoo in India.
              <br/>
             <br/>
             Location: Kerala, India. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Technology: Shopify.
             </div>
         </div>
    </div>


<div className="image-background-portfolio image-background-onlymat">
<div className="svg-image-style">
    <img src="../../img/Only-Mat-new.svg" className="" />
</div>
</div>


    {/*<img  src="../../img/stories-upon-stories-detail.svg" />*/}
  
    {/*<h4>main menu (burger menu)</h4>
    <img src="img/burger-menu-3.jpg"/>*/}
</section>

<section className="prev_next_section">
    <div className="prev_next_project">
        <a className="prev_container" href="/website/francisparker">
            <p> <img src="/img/Vector.svg"/>&nbsp;&nbsp;&nbsp; Francis Parker School</p>
        </a>
        <a className="next_container" href="/e-commerce/togetherhome">
            <p>Together Homes  &nbsp;&nbsp; <img src="/img/Vector-2.svg"/></p>
        </a>
    </div>
</section>
<Footer> </Footer>


</div>


    )
  }
}

