import React, { Component } from 'react';
import { NavLink,Link } from "react-router-dom";
import './style.css';
import ReactImageAppear from 'react-image-appear';
import LazyLoad from 'react-lazyload';
import Header from '../../components/header/index.jsx';
import Footer from '../../components/footer/index.jsx';

export default class CloudbookkeeperdetailPage extends Component {


  render() {

    return (
   

<div className="">



<Header> </Header>

<section className="single_post_section">
    <div className="single_post_desc single-post-single-line">
     
        <div className="single_post_title">
          <div>  <div className="single_post_tag single_post_tag_color_orange">Website</div>
            <p className="website-by">Designed By ANKIT</p></div>
            <h1>Cloud Bookkeeper</h1>
            
        </div>
        {/*<div className="single_post_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
         Duis aute irure dolor in. quis nostrud exercitation ullamco laboris uis nostrud 
         exercitation</div>*/}
         <div>
             
             <div className="single_post_text">The website that specialises in online bookkeeping services for the businesses across all industries.
             <br/>
             <br/>
             Location: California, USA. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Technology: Wordpress.
             </div>
         </div>
    </div>
    {/*<h4>home page</h4>*/}
<div className="image-background">
<LazyLoad>
      <ReactImageAppear className=""  src="../../img/cloud-bookkeeper-d.png"  />
</LazyLoad>
</div>



    {/*<h4>main menu (burger menu)</h4>
    <img src="img/burger-menu-3.jpg"/>*/}
</section>


<section className="prev_next_section">
    <div className="prev_next_project">
        <a className="prev_container" href="/website/stories-upon-stories">
      
            <p><img src="/img/Vector.svg"/>&nbsp;&nbsp;&nbsp; Stories upon stories</p>
        </a>
        <a className="next_container" href="/website/hlb">
            
            <p>HLB &nbsp;&nbsp; <img src="/img/Vector-2.svg"/></p>
        </a>
    </div>
</section>

<Footer> </Footer>



</div>


    )
  }
}

