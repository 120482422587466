import React from 'react';
import {BrowserRouter as Router,Route,Switch,Link,Redirect} from "react-router-dom";

import logo from './logo.svg';

// Pages
import AllProjects from './pages/all-projects/index.jsx';
import Ecommerce from './pages/e-commerce/index.jsx';
import MobileApps from './pages/mobile-apps/index.jsx';
import WebApplications from './pages/web-applications/index.jsx';
import Website from './pages/website/index.jsx';
import KokanedetailPage from './pages/kokane/index.jsx';
import HlbdetailPage from './pages/hlb/index.jsx';
import CloudbookkeeperdetailPage from './pages/cloudbookkeeper/index.jsx';
import StoriesdetailPage from './pages/stories-upon-stories/index.jsx';
import LunaseadetailPage from './pages/lunasea/index.jsx';
import MaikeincdetailPage from './pages/maikeinc/index.jsx';
import SpeakincdetailPage from './pages/speakinc/index.jsx';
import WarpedtabledetailPage from './pages/wrapedtable/index.jsx';
import AwxcdetailPage from './pages/awxc/index.jsx';
import FrancisparkerdetailPage from './pages/francisparker/index.jsx';
import DancegurudetailPage from './pages/dancegruv/index.jsx';
import TogetherhomedetailPage from './pages/togetherhome/index.jsx';
import PriisdetailPage from './pages/priis/index.jsx';
import TomsonelectronicsdetailPage from './pages/tomsonelectronics/index.jsx';
import OnlymatdetailPage from './pages/onlymat/index.jsx';
import ShopurbandetailPage from './pages/shopurbanalchemy/index.jsx';
import BelldetailPage from './pages/bel-organics/index.jsx';
import CharstudiodetailPage from './pages/char-studio/index.jsx';
import FashinovadetailPage from './pages/fashinova/index.jsx';
import CloudbookkeeperdetailPage2 from './pages/cloudbookkeeper-2/index.jsx';
import CarparkdetailPage from './pages/carpark-studio/index.jsx';
import FerencdetailPage from './pages/ferenc/index.jsx';
import LvivbuddetailPage from './pages/lvivbud/index.jsx';
import MediciplusdetailPage from './pages/medici-plus/index.jsx';
import NanovodetailPage from './pages/nanovo/index.jsx';
import Maikeinc2detailPage from './pages/maikeinc-2/index.jsx';
import Maikeinc3detailPage from './pages/maikeinc-3/index.jsx';
import Speakinc2detailPage from './pages/speakinc-2/index.jsx';
function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={AllProjects} />
        <Route exact path="/e-commerce" component={Ecommerce} />
        <Route exact path="/mobile-apps" component={MobileApps} />
        <Route exact path="/web-applications" component={WebApplications} />
        <Route exact path="/website" component={Website} />
        <Route exact path="/kokane" component={KokanedetailPage} />
        <Route exact path="/hlb" component={HlbdetailPage} />
        <Route exact path="/cloudbookkeeper" component={CloudbookkeeperdetailPage} />
        <Route exact path="/stories" component={StoriesdetailPage} />
        <Route exact path="/speakinc" component={SpeakincdetailPage} />
        <Route exact path="/maikeinc" component={MaikeincdetailPage} />
        <Route exact path="/warpedtable" component={WarpedtabledetailPage} />
        <Route exact path="/awxc" component={AwxcdetailPage} />
        <Route exact path="/francisparker" component={FrancisparkerdetailPage} />
        <Route exact path="/dancegruv" component={DancegurudetailPage} />
        <Route exact path="/togetherhome" component={TogetherhomedetailPage} />
        <Route exact path="/website/kokane" component={KokanedetailPage} />
        <Route exact path="/website/hlb" component={HlbdetailPage} />
        <Route exact path="/website/cloudbookkeeper" component={CloudbookkeeperdetailPage} />
        <Route exact path="/website/stories-upon-stories" component={StoriesdetailPage} />
        <Route exact path="/web-applications/speakinc" component={SpeakincdetailPage} />
        <Route exact path="/web-applications/maikeinc" component={MaikeincdetailPage} />
        <Route exact path="/e-commerce/warpedtable" component={WarpedtabledetailPage} />
        <Route exact path="/website/awxc" component={AwxcdetailPage} />
        <Route exact path="/website/francisparker" component={FrancisparkerdetailPage} />
        <Route exact path="/website/dancegruv" component={DancegurudetailPage} />
        <Route exact path="/e-commerce/togetherhome" component={TogetherhomedetailPage} />
        <Route exact path="/e-commerce/priis" component={PriisdetailPage} />
        <Route exact path="/e-commerce/tomsonelectronics" component={TomsonelectronicsdetailPage} />
        <Route exact path="/e-commerce/onlymat" component={OnlymatdetailPage} />
        <Route exact path="/e-commerce/shopurbanalchemy" component={ShopurbandetailPage} />
        <Route exact path="/website/bel-organics" component={BelldetailPage} />
        <Route exact path="/web-applications/char-studio" component={CharstudiodetailPage} />
        <Route exact path="/mobile-apps/fashinova" component={FashinovadetailPage} />
        <Route exact path="/mobile-apps/carpark-studio" component={CarparkdetailPage} />
        <Route exact path="/website/ferenc" component={FerencdetailPage} />
        <Route exact path="/website/lvivbud" component={LvivbuddetailPage} />
        <Route exact path="/website/medici-plus" component={MediciplusdetailPage} />
        <Route exact path="/e-commerce/nanovo" component={NanovodetailPage} />
        <Route exact path="/website/cloudbookkeeper-2" component={CloudbookkeeperdetailPage2} />
        <Route exact path="/web-applications/maikeinc-2" component={Maikeinc2detailPage} />
        <Route exact path="/web-applications/maikeinc-3" component={Maikeinc3detailPage} />
         <Route exact path="/web-applications/speakinc-2" component={Speakinc2detailPage} />
        <Redirect to="/" /> 
      </Switch>
    </Router>
  );
}

export default App;
