import React, { Component } from 'react';
import { NavLink,Link } from "react-router-dom";
import $ from 'jquery';
import './style.css';
import './layout.css';

export default class Header extends Component {

componentDidMount()
{

    //mob menu
    $(".burger_menu").on('click',function() {
        $(".menu").css({'left':'0','transition':'0.5s'});
        $(".mob_menu_close").css({'transition':'0.5s','left':'86%'});
        $(".social_container").css({'transition':'0.5s','left':'0'});
    });
    $(".mob_menu_close").on('click',function() {
        $(".menu").css({'left':'100%','transition':'0.5s'});
        $(".mob_menu_close").css({'transition':'0.5s','left':'100%'});
        $(".social_container").css({'transition':'0.5s','left':'100%'});
    });
}


constructor(props) {    
    super(props)
    this.state = {
    }
    this.AllProjects = this.AllProjects.bind(this);
    this.Ecommerce = this.Ecommerce.bind(this);
    this.MobileApps = this.MobileApps.bind(this);
    this.WebApplications = this.WebApplications.bind(this);
    this.Website = this.Website.bind(this);

  }

  AllProjects() {
    this.props.history.push('/');
  }
  Ecommerce() {
    this.props.history.push('/e-commerce');
  }

  MobileApps() {
    this.props.history.push('/mobile-apps');
  }
  WebApplications() {
    this.props.history.push('/web-applications');
  }
  Website() {
    this.props.history.push('/website');
  }


  render() {
    return (
    
<div>

  
<header>
        <div className="container">
         <a href="/home">
            <img src="/img/hashtag-new-logo-header.svg" />
          </a>
          <nav className="menu">
            <ul>
              <li><NavLink exact={true} activeClassName='is-active' to="/">All</NavLink></li>
              <li><NavLink activeClassName='is-active' to="/e-commerce">E-Commerce</NavLink></li>
              <li><NavLink activeClassName='is-active' to="/mobile-apps">Mobile Apps</NavLink></li>
              <li><NavLink activeClassName='is-active' to="/web-applications">Web Applications</NavLink></li>
              <li><NavLink activeClassName='is-active' to="/website">Website</NavLink></li>
            </ul>
          </nav>
          <div className="social_container">
            <a href="https://www.instagram.com/hashtagsystems_inc/?hl=en" target="_blank">
              <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.4107 3.36723C23.1762 2.73167 22.802 2.15639 22.3158 1.6842C21.8436 1.198 21.2687 0.823817 20.6328 0.589274C20.117 0.38896 19.3422 0.150528 17.9151 0.0855712C16.3713 0.0151695 15.9085 0 12.0002 0C8.09153 0 7.62867 0.0147805 6.08528 0.0851822C4.65819 0.150528 3.88299 0.38896 3.36762 0.589274C2.73167 0.823817 2.15639 1.198 1.68459 1.6842C1.19839 2.15639 0.824206 2.73128 0.589274 3.36723C0.38896 3.88299 0.150528 4.65819 0.0855712 6.08528C0.0151695 7.62867 0 8.09153 0 12.0002C0 15.9085 0.0151695 16.3713 0.0855712 17.9151C0.150528 19.3422 0.38896 20.117 0.589274 20.6328C0.824206 21.2687 1.198 21.8436 1.6842 22.3158C2.15639 22.802 2.73128 23.1762 3.36723 23.4107C3.88299 23.6114 4.65819 23.8499 6.08528 23.9148C7.62867 23.9852 8.09115 24 11.9998 24C15.9089 24 16.3717 23.9852 17.9147 23.9148C19.3418 23.8499 20.117 23.6114 20.6328 23.4107C21.9093 22.9183 22.9183 21.9093 23.4107 20.6328C23.611 20.117 23.8495 19.3422 23.9148 17.9151C23.9852 16.3713 24 15.9085 24 12.0002C24 8.09153 23.9852 7.62867 23.9148 6.08528C23.8499 4.65819 23.6114 3.88299 23.4107 3.36723ZM12.0002 19.5161C7.84882 19.5161 4.48354 16.1512 4.48354 11.9998C4.48354 7.84844 7.84882 4.48354 12.0002 4.48354C16.1512 4.48354 19.5165 7.84844 19.5165 11.9998C19.5165 16.1512 16.1512 19.5161 12.0002 19.5161ZM19.8136 5.94292C18.8436 5.94292 18.0571 5.15644 18.0571 4.18638C18.0571 3.21631 18.8436 2.42983 19.8136 2.42983C20.7837 2.42983 21.5702 3.21631 21.5702 4.18638C21.5698 5.15644 20.7837 5.94292 19.8136 5.94292Z" />
              </svg>
            </a>
            <a href="https://www.facebook.com/hashtagsystemsca" target="_blank">
              <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M23.4107 3.36723C23.1762 2.73167 22.802 2.15639 22.3158 1.6842C21.8436 1.198 21.2687 0.823817 20.6328 0.589274C20.117 0.38896 19.3422 0.150528 17.9151 0.0855712C16.3713 0.0151695 15.9085 0 12.0002 0C8.09153 0 7.62867 0.0147805 6.08528 0.0851822C4.65819 0.150528 3.88299 0.38896 3.36762 0.589274C2.73167 0.823817 2.15639 1.198 1.68459 1.6842C1.19839 2.15639 0.824206 2.73128 0.589274 3.36723C0.38896 3.88299 0.150528 4.65819 0.0855712 6.08528C0.0151695 7.62867 0 8.09153 0 12.0002C0 15.9085 0.0151695 16.3713 0.0855712 17.9151C0.150528 19.3422 0.38896 20.117 0.589274 20.6328C0.824206 21.2687 1.198 21.8436 1.6842 22.3158C2.15639 22.802 2.73128 23.1762 3.36723 23.4107C3.88299 23.6114 4.65819 23.8499 6.08528 23.9148C7.62867 23.9852 8.09115 24 11.9998 24C15.9089 24 16.3717 23.9852 17.9147 23.9148C19.3418 23.8499 20.117 23.6114 20.6328 23.4107C21.9093 22.9183 22.9183 21.9093 23.4107 20.6328C23.611 20.117 23.8495 19.3422 23.9148 17.9151C23.9852 16.3713 24 15.9085 24 12.0002C24 8.09153 23.9852 7.62867 23.9148 6.08528C23.8499 4.65819 23.6114 3.88299 23.4107 3.36723ZM9.66161 23.9487V15.8756H6.37274V11.9979H9.66161V9.14443C9.66161 5.83509 11.6585 4.03391 14.5765 4.03391C15.9738 4.03391 17.1749 4.1393 17.525 4.18633V7.64502H15.5129C13.9255 7.64502 13.6181 8.40831 13.6181 9.5282V11.9979H17.4138L16.9191 15.8756H13.6181V23.9487H9.66161Z" />
              </svg>
            </a>
            <a className="btn_contact" href="https://hashtag-ca.com/contact-us" target="_blank">
              <svg width={18} height={14} viewBox="0 0 18 14" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0)">
                  <path d="M17.3629 1.05151C14.9673 3.06001 10.7639 6.59357 9.52841 7.69534C9.36256 7.84404 9.18467 7.91964 9 7.91964C8.8157 7.91964 8.63812 7.84475 8.47265 7.69675C7.23613 6.59391 3.03266 3.06001 0.637056 1.05151C0.487909 0.926707 0.46517 0.707332 0.58592 0.554749C0.865057 0.202162 1.28124 0 1.72728 0H16.2727C16.7187 0 17.1349 0.202163 17.414 0.554783C17.5348 0.707333 17.5121 0.926707 17.3629 1.05151Z" />
                  <path d="M0.210832 2.40997C0.339731 2.35057 0.491368 2.37129 0.598618 2.46201C2.16752 3.7786 4.23463 5.51814 5.79358 6.84563C5.87561 6.91525 5.92177 7.01755 5.91999 7.12477C5.91822 7.23166 5.86814 7.33254 5.78363 7.39933C4.3351 8.54788 2.15222 10.1278 0.575879 11.2507C0.513015 11.2957 0.438457 11.3185 0.363525 11.3185C0.306695 11.3185 0.2499 11.3056 0.197672 11.2788C0.0762062 11.2173 -0.000123024 11.0935 -0.000123024 10.9585V2.73656C-8.86917e-05 2.5963 0.0823088 2.46869 0.210832 2.40997Z" />
                  <path d="M17.424 11.2507C15.8473 10.1278 13.664 8.5479 12.2159 7.39932C12.1314 7.33253 12.0813 7.23162 12.0795 7.12477C12.0778 7.01754 12.1239 6.91525 12.2059 6.84562C13.7649 5.51813 15.8324 3.77859 17.4013 2.462C17.5092 2.37128 17.6612 2.35127 17.789 2.40996C17.9176 2.46869 18 2.59629 18 2.73656V10.9586C18 11.0936 17.9236 11.2173 17.8022 11.2789C17.75 11.3056 17.6932 11.3186 17.6364 11.3186C17.5614 11.3186 17.4869 11.2957 17.424 11.2507Z" />
                  <path d="M0.718334 12.5002C2.2407 11.4219 5.04505 9.41172 6.71905 8.06662C6.85541 7.9566 7.05287 7.96011 7.18709 8.07472C7.51555 8.35842 7.79043 8.59751 7.98539 8.77115C8.58413 9.30622 9.4151 9.30622 10.0152 8.77044C10.2095 8.59714 10.4843 8.35734 10.8128 8.07469C10.946 7.95936 11.1438 7.95585 11.2805 8.06659C12.9489 9.4071 15.7564 11.4198 17.2812 12.5001C17.3661 12.5606 17.4208 12.6538 17.4314 12.7568C17.4417 12.8598 17.4073 12.9621 17.3359 13.038C17.0607 13.3316 16.6729 13.5 16.2727 13.5H1.72719C1.32696 13.5 0.93955 13.3316 0.663618 13.0381C0.592607 12.9625 0.5578 12.8602 0.568096 12.7572C0.578766 12.6542 0.63345 12.5607 0.718334 12.5002Z" />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width={18} height="13.5" transform="matrix(-1 0 0 1 18 0)" />
                  </clipPath>
                </defs>
              </svg>
              contact us
            </a>
          </div>
          <div className="burger_menu">
            <i className="fa fa-bars" aria-hidden="true" />
          </div>
          <div className="mob_menu_close">
            <i className="fa fa-times" aria-hidden="true" />
          </div>
        </div>
      </header>

</div>



    )
  }
}
