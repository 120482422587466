import React, { Component } from 'react';
import { NavLink,Link } from "react-router-dom";
import './style.css';
import ReactImageAppear from 'react-image-appear';
import LazyLoad from 'react-lazyload';
import Header from '../../components/header/index.jsx';
import Footer from '../../components/footer/index.jsx';

export default class HlbdetailPage extends Component {
    constructor(props) {    
    super(props)
    this.state = {
     loading: true
    }

  }

componentDidMount(){
   setTimeout(()=>{
      this.setState({loading:false});
   },3000); 
}

  render() {

  

    return (
   

<div className="">



<Header> </Header>

<section className="single_post_section">
    <div className="single_post_desc">
    
        <div className="single_post_title">
           <div> <div className="single_post_tag single_post_tag_color_orange">Website</div>
             <p className="website-by">Designed By ANKIT</p></div>
            <h1>HLB</h1>
            
        </div>
        {/*<div className="single_post_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
         Duis aute irure dolor in. quis nostrud exercitation ullamco laboris uis nostrud 
         exercitation</div>*/}
         <div>
             
             <div className="single_post_text">Highly professional website design which focus on the personality of our client's business.
             <br/>
             <br/>
             Location: Texas, USA. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Technology: Wordpress.
             </div>
         </div>
    </div>

<div className="image-background-portfolio image-background-hlb">
<div className="svg-image-style">
    <img src="../../img/hlb.svg" className="maikeinc-style" />
</div>
</div>
   
  
</section>

<section className="prev_next_section">
    <div className="prev_next_project">
        <a className="prev_container" href="/website/Cloudbookkeeper">
            <p><img src="/img/Vector.svg"/>&nbsp;&nbsp;&nbsp; Cloud Bookkeeper</p>
        </a> 
        <a className="next_container" href="/website/awxc">
            <p>AWXC &nbsp;&nbsp; <img src="/img/Vector-2.svg"/></p>
        </a>
    </div>
</section>
<Footer> </Footer>



</div>


    )
  }
}


