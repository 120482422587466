import React, { Component } from 'react';
import { NavLink,Link } from "react-router-dom";
import './style.css';
import Header from '../../components/header/index.jsx';
import Footer from '../../components/footer/index.jsx';

export default class WebApplications extends Component {
  render() {
    return (
   
<div className="main-web-apps-page">
<Header> </Header>
  
<section className="all_projects_section" id="all_projects_section">
        <h1>Web Applications</h1>
        <div className="posts posts-new-two">
        
           <div className="post">
            <div className="post_on_hover">
              <a href="web-applications/maikeinc">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>MAIKE INC</span>
              </a>
            </div>
            <div className="post_img">
              <img src="img/maikeinc-detail.svg" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_blue">Web Application</div>
              {/*<span className="post_title">MAIKE INC</span>*/}
            </div>
          </div>

          <div className="post">
            <div className="post_on_hover">
              <a href="web-applications/speakinc">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>Speakinc</span>
              </a>
            </div>
            <div className="post_img">
              <img src="img/speakinc-preview-new.svg" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_orange">Web Application</div>
               {/*<span className="post_title">Speakinc</span>*/}
            </div>
          </div>
          
        </div>
        
       
      </section>
      <Footer> </Footer>
</div>

    )
  }
}