import React, { Component } from 'react';
import { NavLink,Link } from "react-router-dom";
import './style.css';
import Header from '../../components/header/index.jsx';
import Footer from '../../components/footer/index.jsx';

export default class AllProjects extends Component {
  render() {
    return (
   
<div>
<Header> </Header>
  
 <section className="all_projects_section" id="all_projects_section">
        <h1>All projects</h1>
        <div className="posts ">
          <div className="post">
            <div className="post_on_hover">
              <a href="website/kokane">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>Ko-Kane</span>
              </a>
            </div>
            <div className="post_img">
              <img src="/img/kokane-main-d.jpg" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_green">Website</div>
              {/*<span className="post_title">Ko-Kane</span>*/}
            </div>
          </div>
           
           <div className="post">
            <div className="post_on_hover">
              <a href="e-commerce/shopurbanalchemy">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>Shop Urban Alchemy</span>
              </a>
            </div>
            <div className="post_img">

              <img src="img/shop-urban-detail.png" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_blue">E-Commerce</div>
              {/*<span className="post_title">DanceGruv Radio</span>*/}
            </div>
          </div>

          <div className="post">
            <div className="post_on_hover">
             <a href="website/hlb">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>Ham Langston & Brezina LLP</span>
              </a>
            </div>
            <div className="post_img">
              <img src="img/hlb-d.jpg" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_red">Website</div>
               {/*<span className="post_title">Ham Langston & Brezina LLP</span>*/}
            </div>
          </div>
              <div className="post">
            <div className="post_on_hover">
              <a href="web-applications/maikeinc">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>Maikeinc</span>
              </a>
            </div>
            <div className="post_img">
              <img src="img/maikeinc-new-bg.jpg" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_blue">Web Application</div>
                {/*<span className="post_title">Maikeinc</span>*/}
            </div>
          </div>

              {/*<div className="post">
            <div className="post_on_hover">
              <a href="web-applications/maikeinc-2">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>Maikeinc</span>
              </a>
            </div>
            <div className="post_img">
              <img src="img/maikeinc-detail.svg" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_blue">Web Application</div>
               
            </div>
          </div>*/}

               <div className="post">
            <div className="post_on_hover">
              <a href="website/stories-upon-stories">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>Stories Upon stories</span>
              </a>
            </div>
            <div className="post_img">

              <img src="img/stories-home-preview.svg" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_orange">Website</div>
              {/*<span className="post_title">Stories Upon stories</span>*/}
            </div>
          </div>
          <div className="post">
            <div className="post_on_hover">
              <a href="website/cloudbookkeeper">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>Cloud-Bookkeeper</span>
              </a>
            </div>
            <div className="post_img">
              <img src="img/cloud-bookkeeper-d.png" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_blue">Website</div>
              {/*<span className="post_title">Cloud-Bookkeeper</span>*/}
            </div>
          </div>


          <div className="post">
            <div className="post_on_hover">
              <a href="web-applications/speakinc">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>Speakinc</span>
              </a>
            </div>
            <div className="post_img">
              <img src="img/speakinc-d.jpg" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_orange">Web Application</div>
               {/*<span className="post_title">Speakinc</span>*/}
            </div>
          </div>
           
          {/* <div className="post">
            <div className="post_on_hover">
              <a href="web-applications/speakinc-2">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>Speakinc2</span>
              </a>
            </div>
            <div className="post_img">
              <img src="img/speakinc-new.svg" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_orange">Web Application</div>
               
            </div>
          </div>*/}

          <div className="post">
            <div className="post_on_hover">
              <a href="e-commerce/tomsonelectronics">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>Tomson Electronics</span>
              </a>
            </div>
            <div className="post_img">

              <img src="img/tomson-detail.png" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_blue">E-Commerce</div>
              {/*<span className="post_title">DanceGruv Radio</span>*/}
            </div>
          </div>
          <div className="post">
            <div className="post_on_hover">
              <a href="website/dancegruv">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>DanceGruv Radio</span>
              </a>
            </div>
            <div className="post_img">

              <img src="img/danceguru-preview-new.svg" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_blue">Website</div>
              {/*<span className="post_title">DanceGruv Radio</span>*/}
            </div>
          </div>
          
           <div className="post">
            <div className="post_on_hover">
              <a href="website/awxc">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>AWXC</span>
              </a>
            </div>
            <div className="post_img">
            <img src="img/awxc-d.jpg" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_red">Website</div>
              {/*<span className="post_title">AWXC</span>*/}
            </div>
          </div>
          <div className="post">
            <div className="post_on_hover">
              <a href="e-commerce/warpedtable">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>The Warped Table</span>
              </a>
            </div>
            <div className="post_img">
              <img src="img/warped-table-d.jpg" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_green">E-Commerce</div>
              {/*<span className="post_title">The Warped Table</span>*/}
            </div>
          </div>
          <div className="post">
            <div className="post_on_hover">
              <a href="e-commerce/priis">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>Priis</span>
              </a>
            </div>
            <div className="post_img">

              <img src="img/priis-detail.png" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_blue">E-commerce</div>
              {/*<span className="post_title">Together Homes</span>*/}
            </div>
          </div>
             <div className="post">
            <div className="post_on_hover">
              <a href="website/francisparker">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>Francis Parker School</span>
              </a>
            </div>
            <div className="post_img">

              <img src="img/francisparker-d.jpg" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_orange">Website</div>
              {/*<span className="post_title">Francis Parker School</span>*/}
            </div>
          </div>
          
             <div className="post">
            <div className="post_on_hover">
              <a href="e-commerce/onlymat">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>Only Mat</span>
              </a>
            </div>
            <div className="post_img">

              <img src="img/onlymat-detail.png" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_blue">E-Commerce</div>
              {/*<span className="post_title">DanceGruv Radio</span>*/}
            </div>
          </div>
         
          <div className="post">
            <div className="post_on_hover">
              <a href="e-commerce/togetherhome">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>Together Homes</span>
              </a>
            </div>
            <div className="post_img">

              <img src="img/together-home-preview.png" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_red">E-commerce</div>
              {/*<span className="post_title">Together Homes</span>*/}
            </div>
          </div>

          <div className="post">
            <div className="post_on_hover">
              <a href="website/bel-organics">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>Bell</span>
              </a>
            </div>
            <div className="post_img">

              <img src="img/bell-organic.jpg" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_red">Website</div>
              {/*<span className="post_title">Together Homes</span>*/}
            </div>
          </div>

            <div className="post">
            <div className="post_on_hover">
              <a href="web-applications/char-studio">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>Char Studio</span>
              </a>
            </div>
            <div className="post_img">

              <img src="img/char-studio.jpg" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_red">Web Application</div>
              {/*<span className="post_title">Together Homes</span>*/}
            </div>
          </div>

           <div className="post">
            <div className="post_on_hover">
              <a href="mobile-apps/fashinova">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>Fashinova</span>
              </a>
            </div>
            <div className="post_img">

              <img src="img/fashinova.jpg" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_blue">Mobile Apps</div>
              {/*<span className="post_title">Together Homes</span>*/}
            </div>
          </div>


           <div className="post">
            <div className="post_on_hover">
              <a href="mobile-apps/carpark-studio">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>Car Park Studio</span>
              </a>
            </div>
            <div className="post_img">

              <img src="img/carpark-mobile.jpg" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_green">Mobile Apps</div>
              {/*<span className="post_title">Together Homes</span>*/}
            </div>
          </div>

          <div className="post">
            <div className="post_on_hover">
              <a href="website/ferenc">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>Ferenc</span>
              </a>
            </div>
            <div className="post_img">

              <img src="img/Ference-hotel.jpg" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_orange">Website</div>
              {/*<span className="post_title">Together Homes</span>*/}
            </div>
          </div>
   
        <div className="post">
            <div className="post_on_hover">
              <a href="website/lvivbud">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>LvivBud</span>
              </a>
            </div>
            <div className="post_img">

              <img src="img/lvivbud_HOME-page.jpg" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_orange">Website</div>
              {/*<span className="post_title">Together Homes</span>*/}
            </div>
          </div>
          
          
        <div className="post">
            <div className="post_on_hover">
              <a href="website/medici-plus">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>Medici Plus</span>
              </a>
            </div>
            <div className="post_img">

              <img src="img/medici-plus.jpg" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_orange">Website</div>
              {/*<span className="post_title">Together Homes</span>*/}
            </div>
          </div>


        <div className="post">
            <div className="post_on_hover">
              <a href="e-commerce/nanovo">
                <svg width={42} height={42} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.7656 38.7188H3.28125V27.2344C3.28125 26.3283 2.54674 25.5938 1.64062 25.5938C0.734508 25.5938 0 26.3283 0 27.2344V40.3594C0 41.2655 0.734508 42 1.64062 42H14.7656C15.6717 42 16.4062 41.2655 16.4062 40.3594C16.4062 39.4533 15.6717 38.7188 14.7656 38.7188Z" fill="white" />
                  <path d="M14.7656 0H1.64062C0.734508 0 0 0.734508 0 1.64062V14.7656C0 15.6717 0.734508 16.4062 1.64062 16.4062C2.54674 16.4062 3.28125 15.6717 3.28125 14.7656V3.28125H14.7656C15.6717 3.28125 16.4062 2.54674 16.4062 1.64062C16.4062 0.734508 15.6717 0 14.7656 0Z" fill="white" />
                  <path d="M40.3594 25.5938C39.4533 25.5938 38.7188 26.3283 38.7188 27.2344V38.7188H27.2344C26.3283 38.7188 25.5938 39.4533 25.5938 40.3594C25.5938 41.2655 26.3283 42 27.2344 42H40.3594C41.2655 42 42 41.2655 42 40.3594V27.2344C42 26.3283 41.2655 25.5938 40.3594 25.5938Z" fill="white" />
                  <path d="M40.3594 0H27.2344C26.3283 0 25.5938 0.734508 25.5938 1.64062C25.5938 2.54674 26.3283 3.28125 27.2344 3.28125H38.7188V14.7656C38.7188 15.6717 39.4533 16.4062 40.3594 16.4062C41.2655 16.4062 42 15.6717 42 14.7656V1.64062C42 0.734508 41.2655 0 40.3594 0Z" fill="white" />
                </svg>
                <span>Nanovo</span>
              </a>
            </div>
            <div className="post_img">

              <img src="img/Nanovo-image.jpg" alt="" />
            </div>
            <div className="post_text">
              <div className="post_tag color_tag_orange">E-Commerce</div>
              {/*<span className="post_title">Together Homes</span>*/}
            </div>
          </div>

          
        </div>
      </section>
      <Footer> </Footer>
</div>

    )
  }
}
