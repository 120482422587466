import React, { Component } from 'react';
import { NavLink,Link } from "react-router-dom";
import './style.css';
import ReactImageAppear from 'react-image-appear';
import LazyLoad from 'react-lazyload';
import Header from '../../components/header/index.jsx';
import Footer from '../../components/footer/index.jsx';


export default class KokanedetailPage extends Component {


  render() {


    return (
   

<div className="">



<Header> </Header>

<section className="single_post_section">
    <div className="single_post_desc single-stories">
   
        <div className="single_post_title">
           <div> <div className="single_post_tag single_post_tag_color_orange">Website</div>
             <p className="website-by">Designed By PURVA DESHPANDE.</p></div>
            <h1>Stories Upon Stories</h1>
            
        </div>

        {/*<div className="single_post_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
         Duis aute irure dolor in. quis nostrud exercitation ullamco laboris uis nostrud 
         exercitation</div>*/}
         <div>
             
             <div className="single_post_text">Incredible narration of stories and beautiful UI of the WordPress site attracts the ones who love to read.
              <br/>
             <br/>
             Location: California, USA. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Technology: Wordpress.
             </div>
         </div>
    </div>

<div className="image-background-portfolio image-background-stories">
<div className="svg-image-style">
    <img src="../../img/stories-upon-stories-detail.svg" className="" />
</div>
</div>

</section>

<section className="prev_next_section">
    <div className="prev_next_project">
        <a className="prev_container" href="/website/hlb">
            <p> <img src="/img/Vector.svg"/>&nbsp;&nbsp;&nbsp; HLB</p>
        </a>
        <a className="next_container" href="/website/cloudbookkeeper">
            <p>Cloud Bookkeeper  &nbsp;&nbsp; <img src="/img/Vector-2.svg"/></p>
        </a>
    </div>
</section>
<Footer> </Footer>


</div>


    )
  }
}

