import React, { Component } from 'react';
import { NavLink,Link } from "react-router-dom";
import './style.css';
import ReactImageAppear from 'react-image-appear';
import LazyLoad from 'react-lazyload';
import Header from '../../components/header/index.jsx';
import Footer from '../../components/footer/index.jsx';

export default class CharstudiodetailPage extends Component {
 
  render() {

    return (
   

<div className="">



<Header> </Header>

<section className="single_post_section">
    <div className="single_post_desc">
    
        <div className="single_post_title">
            <div><div className="single_post_tag single_post_tag_color_orange">Web Applications</div>
            
   <p className="website-by">Designed By Evgeniy Shapovalov.</p></div>
            <h1>Char Studio</h1>
            
        </div>
        {/*<div className="single_post_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
         Duis aute irure dolor in. quis nostrud exercitation ullamco laboris uis nostrud 
         exercitation</div>*/}
          <div>
             
             <div className="single_post_text">CharStudio has been taking its place in the game industry since 2013.
They are creating kind and relaxing games, bringing positive and joyful moments for the players.
Their games have been downloaded more than 10 millions of times from Google PlayMarket and AppStore.
In this design we tried to reproduce the atmosphere in their friendly office and to transfer to the website user
the energy from their products.
              <br/>
             <br/>
             Location: California, USA. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Technology: Python.
             </div>
         </div>
    </div>
<div className="image-background">
<LazyLoad>
  <ReactImageAppear className=""  src="../../img/Char-Studio.svg"  />
</LazyLoad>
 </div>   
</section>

<section className="prev_next_section">
    <div className="prev_next_project">
        <a className="prev_container" href="/web-applications/maikeinc">
            <p><img src="/img/Vector.svg"/>&nbsp;&nbsp;&nbsp; MaikeInc</p>
        </a>
        <a className="next_container" href="/web-applications/speakinc">
            <p>Speakinc &nbsp;&nbsp; <img src="/img/Vector-2.svg"/></p>
        </a>
    </div>
</section>
<Footer> </Footer>


</div>


    )
  }
}

