import React, { Component } from 'react';
import { NavLink,Link } from "react-router-dom";
import './style.css';
import Header from '../../components/header/index.jsx';
import Footer from '../../components/footer/index.jsx';

export default class KokanedetailPage extends Component {


  render() {
    return (
   

<div className="">



<Header> </Header>
<section className="single_post_section">
    <div className="single_post_desc">
        <div className="single_post_title">
            <div className="single_post_tag single_post_tag_color_orange">Website</div>
            <h1>Lunasea Lightning</h1>
            
        </div>
        {/*<div className="single_post_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
         Duis aute irure dolor in. quis nostrud exercitation ullamco laboris uis nostrud 
         exercitation</div>*/}
    </div>
  
    <img src="../../img/lunasea-detail.svg" />
    {/*<h4>main menu (burger menu)</h4>
    <img src="img/burger-menu-3.jpg"/>*/}
</section>
<section className="prev_next_section">
    <div className="prev_next_project">
        <a className="prev_container" href="/cloudbookkeeper">
            <span>Previous project</span>
            <p>Cloud Bookkeeper</p>
        </a>
        <a className="next_container" href="/hlb">
            <span>Next project</span>
            <p>HLB</p>
        </a>
    </div>
</section>
<Footer> </Footer>


</div>


    )
  }
}

