import React, { Component } from 'react';
import { NavLink,Link } from "react-router-dom";
import './style.css';
import ReactImageAppear from 'react-image-appear';
import LazyLoad from 'react-lazyload';
import Header from '../../components/header/index.jsx';
import Footer from '../../components/footer/index.jsx';

export default class FrancisparkerdetailPage extends Component {



  render() {

 
    return (
   

<div className="">



<Header> </Header>

<section className="single_post_section">
    <div className="single_post_desc single-post-single-line-francis">
     
        <div className="single_post_title">
            <div><div className="single_post_tag single_post_tag_color_orange">Website</div>
              <p className="website-by">Designed By SUDHINA AP</p></div>
            <h1>Francis Parker School</h1>
            
        </div>
        {/*<div className="single_post_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
         Duis aute irure dolor in. quis nostrud exercitation ullamco laboris uis nostrud 
         exercitation</div>*/}

         <div>
             
             <div className="single_post_text">A CMS - based WordPress website which gives an in-depth idea on  the independent, coeducational, college preparatory day school for students in Junior Kindergarten through Grade 12.
              <br/>
             <br/>
             Location: San Diego, California. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Technology: Wordpress.
             </div>
         </div>
          
    </div>
 


<div className="image-background-portfolio image-background-francis">
<div className="svg-image-style">
    <img src="../../img/francis-parker-new.svg" className="" />
</div>
</div>


    {/*<h4>main menu (burger menu)</h4>
    <img src="img/burger-menu-3.jpg"/>*/}
</section>

<section className="prev_next_section">
    <div className="prev_next_project">
        <a className="prev_container" href="/website/awxc">
            <p><img src="/img/Vector.svg"/>&nbsp;&nbsp;&nbsp; AWXC</p>
        </a>
        <a className="next_container" href="/website/dancegruv">
            <p>DanceGruv Radio &nbsp;&nbsp; <img src="/img/Vector-2.svg"/></p>
        </a>
    </div>
</section>
<Footer> </Footer>


</div>


    )
  }
}

